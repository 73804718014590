import { css } from 'styled-components'

export const colors = { black: '#000', white: '#fff', brandText: '#D1898F' }

export const colspan = cols => {
  return `${(100 / 12) * cols}vw`
}

const sizes = {
  wide: 1400,
  desktop: 992,
  tablet: 850,
  phone: 500,
}

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `

  return acc
}, {})

export const rhythm = (factor = 4) => `${factor * 1.5}rem`
