import React from 'react'
import styled from 'styled-components'

import Headroom from 'react-headroom'

import Header from '../Header'

const Sticky = styled(Headroom)`
  position: relative;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  .headroom {
    transition: 300ms;
    transform: translateY(-105%);
  }

  .headroom--scrolled {
    transform: translateY(0%);
  }
`

const StickyHeader = () => (
  <Sticky disableInlineStyles pinStart={200}>
    <Header compact />
  </Sticky>
)

export default StickyHeader
