import React from 'react'
import styled from 'styled-components'

import logo from '../img/mini-logo.svg'

import { media, rhythm } from '../settings/styles'

const Wrapper = styled.footer`
  color: #d1898f;
`
const Grid = styled.div`
  border-top: 1px solid #d1898f;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: ${rhythm(4)} ${rhythm(3)};

  ${media.tablet`
    padding-left: 0;
    padding-right: 0;
  `};

  ${media.phone`
    padding-top: ${rhythm(2)};
    padding-bottom: ${rhythm(2)};
  `};
`

const Col = styled.div`
  line-height: 1.5;
  letter-spacing: 0.25em;
  text-decoration: none;
  font-size: 13px;

  flex-grow: ${props => (props.small ? '1' : '2')};
  min-width: 18%;
  padding-right: 2rem;

  strong {
    text-transform: uppercase;
    font-weight: normal;
  }

  ${media.tablet`
    &.non-critical {
      display: none;
    }

    ${media.phone`
      width: 100%;
      text-align: center;
      padding-right: 0;      
      padding-top: ${rhythm(1)};
    padding-bottom: ${rhythm(1)};
    `}
  `};

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Logo = styled.img`
  height: ${1.5 * 4}rem;
  width: auto;
`

const Footer = () => (
  <Wrapper>
    <Grid className="l-constrained-wide">
      <Col small className="non-critical">
        <Logo src={logo} alt="Wow My Day" />
      </Col>
      <Col>
        <strong>
          <a href="mailto:smile@wowmyday.be">Questions?</a>
        </strong>
        <br />
        <br />
        just send us a mail <br />
        <a href="mailto:smile@wowmyday.be">smile@wowmyday.be</a>
      </Col>
      <Col>
        <strong>
          Hear our voice? <br />
        </strong>
        <br />
        Charlotte — <a href="tel:0032474471147">0474 47 11 47</a> <br />
        Flore — <a href="tel:0032494156300">0494 15 63 00</a>
      </Col>
      <Col small>
        <strong>
          Follow us <br />
        </strong>
        <br />
        <a
          href="https://www.instagram.com/wowmyday.be/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <br />
        <a
          href="https://www.facebook.com/wowmyday.be/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
      </Col>
    </Grid>
  </Wrapper>
)

export default Footer
