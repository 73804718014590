import React from 'react'
import Helmet from 'react-helmet'

import Header from './Header'
import StickyHeader from './StickyHeader'
import Footer from './Footer'
import { createGlobalStyle } from 'styled-components'

import { media, colspan } from '../settings/styles'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Poppins:400,700');
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    font-family: 'Poppins', sans-serif;
    overflow-y: scroll;
    min-width: 320px;

    font-size: 17px;
    line-height: 1.5;

    ${media.tablet`
      font-size: 14px;
    `}


    ${media.phone`
      font-size: 13px;
    `}
  }

  body {
    background: #F7F5F0;
  }

  .l-constrained {
    width: ${colspan(8)};
    margin: 0 auto;
    max-width: 1000px;

    ${media.phone`
      width: 90vw;    
    `}
  }

  .l-constrained-narrow {
    width: ${colspan(8)};
    margin: 0 auto;
    max-width: 700px;

    ${media.phone`
      width: 90vw;    
    `}
  }

  .l-constrained-wide {
    width: ${colspan(10)};
    margin: 0 auto;
    max-width: 1200px;

    ${media.desktop`
      width: 95vw;    
    `}
  }
`

const Layout = ({ children, location }) => (
  <div>
    <GlobalStyle />
    <Helmet
      title="Wow my day"
      meta={[
        {
          name: 'description',
          content:
            'wow my day – a shout of pure happiness and positive vibes. We love how flowers and greens can add a sparkle to a party. Whether intimate or supreme, we make your table bouquets, flower arrangements, floral crowns and so much more.',
        },
        { name: 'format-detection', content: 'telephone=no' },
      ]}
    />
    <StickyHeader />
    <Header />
    {children}
    <Footer />
  </div>
)

export default Layout
