import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { rhythm, media, colors } from '../../settings/styles'

import Menu from '../Menu'
import logo from '../../img/logo.svg'
import miniLogo from '../../img/mini-logo.svg'
import Cart from '../Cart'

const Wrapper = styled.header`
  margin-bottom: ${rhythm(2)};

  padding: ${props => (props.compact ? rhythm(1) : 0)};
  /* ${media.desktop`
    padding: ${props => (props.compact ? rhythm(1) : 0)};  
  `} */
  background: ${props => (props.compact ? colors.brandText : 'transparent')};
  color: ${props => (props.compact ? '#fff' : '#8e595d')};
`
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #d1898f;
    height: ${props => (props.compact ? '15px' : '28px')};

    ${media.tablet`
      display: none;
    `};
  }
`

const Top = styled.div`
  height: ${props => (props.compact ? 'auto' : '200px')};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Logo = styled.img`
  margin-top: ${rhythm(2)};
`

const MiniLogo = styled.img`
  position: relative;
  top: ${rhythm(1.3)};

  ${media.desktop`
    display: none;
  `};
`

const Header = props => {
  const { compact } = props
  return (
    <Wrapper compact={compact}>
      <Inner compact={compact} className="l-constrained-wide">
        {!compact && (
          <Top>
            <Link to="/">
              <Logo src={logo} alt="Wow my day" />
            </Link>
          </Top>
        )}

        <Menu />
        {!compact && <Cart></Cart>}
      </Inner>
    </Wrapper>
  )
}

export default Header
