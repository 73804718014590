import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { media } from '../settings/styles'

const MenuEl = styled.nav`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  ${media.desktop`
    justify-content: center;
    a {
      margin: .7em;
    }
    a:first-child {
        display: none;
      }
  `}

  a {
    flex-basis: 20%;
    color: inherit;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    font-size: 13px;
    color: inherit;

    ${media.phone`
      flex-basis: auto;
      font-size: 10px;

    `};

    span {
      position: relative;
      display: inline-block;
    }
    span::after {
      top: 100%;
      margin-top: 0.4em;
      width: 100%;
      position: absolute;
      content: '';
      display: block;
      transform: scaleX(0);
      border-bottom: 1px solid currentColor;
      transform-origin: left;
      transition: 300ms;
    }
  }

  a:hover span::after {
    transform: scaleX(1);
  }

  .is-active {
    font-weight: bold;
    span::after {
      transform: scaleX(1);
    }
  }
`

const Menu = () => {
  const MenuLinks = [
    { name: 'Home', to: '/' },
    { name: 'About', to: '/about' },
    { name: 'Order Flowers', external: 'https://shop.wowmyday.be' },
    { name: 'Projects', to: '/projects' },
    { name: 'Contact', to: '/contact' },
  ]

  return (
    <MenuEl>
      {MenuLinks.map(item => {
        return item.external ? (
          <a key={item.name} href={item.external} rel="noreferrer noopener">
            {item.name}
          </a>
        ) : (
          <Link activeClassName="is-active" key={item.name} to={item.to}>
            <span>{item.name}</span>
          </Link>
        )
      })}
    </MenuEl>
  )
}

export default Menu
